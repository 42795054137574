import { Typography } from '@material-ui/core';
import React from 'react';
import useStatusIndicatorStyles from 'styles/StatusIndicator.styles';

const StatusIndicator = ({ enabled }: { enabled: boolean }): JSX.Element => {
  const classes = useStatusIndicatorStyles({ enabled });
  return (
    <div className={classes.wrapper} data-testid="status-indicator">
      <div className={classes.indicator} />
      <Typography className={classes.text}>{enabled ? 'Enabled' : 'Disabled'}</Typography>
    </div>
  );
};

export default StatusIndicator;
