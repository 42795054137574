import { useAuthenticationService } from 'admin-portal-shared-services';
import config from 'countries.config.json';
import _ from 'lodash';
import React, { createContext, useContext } from 'react';

type UserData = {
  country: string;
  supportedCountries: string[];
  isGlobalManager: boolean;
};

const UserContext = createContext<UserData>({} as UserData);
UserContext.displayName = 'UserContext';

const UserProvider = (props: { children: JSX.Element }): JSX.Element => {
  const { children } = props;
  const { user_country: country } = useAuthenticationService().getUserCountryAndLanguage();
  const countries = useAuthenticationService().getSupportedCountries();
  const zones = Object.keys(config);
  // @ts-expect-error scopes exist on JWT
  const { scopes } = useAuthenticationService().parseJwt();
  const isGlobalManager = Array.isArray(scopes) && scopes.includes('CategoryAdmin.Country.All');
  // TODO find out how to mock scope for isGlobalManager
  // istanbul ignore next
  const supportedCountries = isGlobalManager ? zones : getAllowedZones(countries);

  const value = {
    country,
    supportedCountries,
    isGlobalManager,
  };
  // @ts-expect-error country should never be falsey
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const getAllowedZones = (supportedCountries: Array<string>) => {
  const countries = Object.entries(config);
  const allowedZones = Object.fromEntries(
    countries.filter(([, countryConfig]) =>
      supportedCountries.some(
        (supportedCountry) => supportedCountry === countryConfig.valueForExternalServices
      )
    )
  );

  return Object.keys(allowedZones);
};

const useUserContext = (): UserData => {
  return useContext(UserContext);
};

export { UserProvider, useUserContext, UserContext };
