type ImageUrls = {
  iconImageURL: string;
  mainImageURL: string;
  mobileImageURL: string;
  hoverImageURL: string;
};

const getUpdatedImageUrls = (url = ''): ImageUrls => ({
  iconImageURL: url,
  mainImageURL: url,
  mobileImageURL: url,
  hoverImageURL: url,
});

export default getUpdatedImageUrls;
