import { useItemContext } from 'context/item-context';
import { useCallback } from 'react';
import { getItems } from 'services/items/ItemService';

const useItemService = (): {
  getAllItems: () => Promise<void>;
} => {
  const { dispatch } = useItemContext();
  const getAllItems = useCallback(async () => {
    let items: any[] = [];
    let page = 0;
    let pageSize = 5000;

    dispatch({ type: 'status updated', payload: 'loading' });

    while (true) {
      try {
        const res = await getItems(page, pageSize);
        if (res && res.data) {
          items.push(...res.data.items);
          if (!res.data.pagination.hasNext) {
            break;
          }
          page++;
        } else {
          break;
        }
      } catch (error) {
        dispatch({ type: 'status updated', payload: 'failed' });
        return;
      }
    }

    dispatch({ type: 'items received', payload: items });
    dispatch({ type: 'status updated', payload: 'succeeded' });
  }, [dispatch]);

  return {
    getAllItems,
  };
};

export default useItemService;
