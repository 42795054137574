import { makeStyles } from '@material-ui/core';
import { EditMode } from 'context/list-context';
import { BG_GRAY, WHITE } from 'themes/customTheme';

type StyleProps = {
  indentation?: number;
  isRoot: boolean;
  isChildless: boolean;
  isParentSelected?: boolean;
  editMode: EditMode | null;
};

const useCategoryListItemStyles = makeStyles({
  // Hide details button by default
  container: {
    '& div > button.details-button': {
      display: 'none',
    },
    // Show details button on hover
    '&:hover': {
      '& div > button.details-button': {
        display: 'flex',
        // Apply background on hover to obscure status indicator
        background: '#F0F0F0',
      },
      '& .badge.badge-normal > span': {
        backgroundColor: WHITE,
      },
    },
  },
  root: ({ indentation, isRoot, isChildless, isParentSelected, editMode }: StyleProps) => {
    // Left padding varies depending on categoryLevel and the existence of child items
    let paddingLeft = 56;
    if (isRoot) {
      paddingLeft = 24;
      // Offset for checkbox padding
      if (editMode === EditMode.select && isChildless) {
        paddingLeft = 22;
      }
      // Offset for drag indicator
      if (editMode === EditMode.sort) {
        paddingLeft = 12;
      }
    }
    if (!isRoot) {
      // Offset for drag indicator
      if (editMode === EditMode.sort) {
        paddingLeft = 44;
      }
      // Offset for checkbox padding
      if (editMode === EditMode.select) {
        paddingLeft = 56;
      }
    }
    return {
      width: 'auto',
      height: 50,
      paddingLeft: paddingLeft + Number(indentation),
      paddingRight: 48,
      // Apply highlighting to child items of selected category
      ...(isParentSelected && { backgroundColor: BG_GRAY }),
      '& .category-item-text': {
        padding: '0 8px 0 0',
      },
    };
  },
});

export default useCategoryListItemStyles;
