import { makeStyles } from '@material-ui/core';
import { DISABLED_RED, ENABLED_GREEN } from 'themes/customTheme';

type StyleProps = {
  enabled: boolean;
};

const useStatusIndicatorStyles = makeStyles({
  wrapper: {
    minWidth: 87,
    display: 'flex',
    alignItems: 'center',
  },
  indicator: ({ enabled }: StyleProps) => {
    return {
      height: 8,
      width: 8,
      borderRadius: '50%',
      marginRight: 10,
      backgroundColor: enabled ? ENABLED_GREEN : DISABLED_RED,
    };
  },
  text: {
    opacity: 0.4,
    color: '#000',
    fontWeight: 100,
  },
});

export default useStatusIndicatorStyles;
