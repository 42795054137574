import { createGenerateClassName, StylesProvider, ThemeProvider } from '@material-ui/core';
import { createFeatureToggleServiceV2 } from 'admin-portal-shared-services';
import { CategoryIcon, ProductIcon } from 'assets/icons';
import AppbarConfig from 'components/AppbarConfig/AppbarConfig';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { CategoryProvider } from 'context/CategoryContext';
import { ItemProvider } from 'context/item-context';
import { ToastProvider } from 'context/toast-context';
import { UserProvider } from 'context/user-context';
import { VendorProvider } from 'context/vendor-context';
import { ZoneProvider } from 'context/zone-context';
import React, { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { defaultTheme } from 'supplier-portal-shared-components';
import customTheme from 'themes/customTheme';
import AppContainer from 'components/AppContainer/AppContainer';
import Router from './Router';

const generateClassName = createGenerateClassName({
  productionPrefix: 'admin-portal-browse-category-beta-mfe',
  seed: 'admin-portal-browse-category-beta-mfe',
});

function App({ optimizelyKey }: { optimizelyKey: string }): JSX.Element {
  useEffect(() => {
    // Insures there is only one instance of FeatureToggleOptimizelyV2 in the window
    /* istanbul ignore next */
    if (
      window.services?.FeatureToggleOptimizelyV2 &&
      // @ts-expect-error allow type any for services object
      window.services?.FeatureToggleOptimizelyV2['admin-portal-browse-category-mfe']
    ) {
      return;
    }
    createFeatureToggleServiceV2('admin-portal-browse-category-mfe', optimizelyKey);
  }, [optimizelyKey]);

  const menu = [
    {
      id: '1',
      title: 'Categories',
      icon: CategoryIcon,
      path: '/browse-category',
    },
    {
      id: '2',
      title: 'Products',
      icon: ProductIcon,
      path: '/browse-category/products',
    },
  ];

  return (
    <ErrorBoundary>
      <AppbarConfig sidebarItems={menu}>
        <ThemeProvider theme={defaultTheme}>
          <StylesProvider generateClassName={generateClassName} injectFirst>
            <ThemeProvider theme={customTheme}>
              <UserProvider>
                <ZoneProvider>
                  <VendorProvider>
                    <CategoryProvider>
                      <ItemProvider>
                        <ToastProvider>
                          <DndProvider backend={HTML5Backend}>
                            <AppContainer>
                              <Router />
                            </AppContainer>
                          </DndProvider>
                        </ToastProvider>
                      </ItemProvider>
                    </CategoryProvider>
                  </VendorProvider>
                </ZoneProvider>
              </UserProvider>
            </ThemeProvider>
          </StylesProvider>
        </ThemeProvider>
      </AppbarConfig>
    </ErrorBoundary>
  );
}

export default App;
