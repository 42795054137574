import { useEnvService } from 'admin-portal-shared-services';
import { getCategories, useCategoryContext } from 'context/CategoryContext';
import { handleSubmit, useNewCategoryContext } from 'context/new-category';
import { showToast, useToastContext } from 'context/toast-context';
import { useVendorContext } from 'context/vendor-context';
import { useZoneContext } from 'context/zone-context';
import { useCallback } from 'react';
import { update } from 'services/categories/CategoryService';
import uploadFile from 'services/file';
import { Category } from 'types';
import { languageCodeString } from 'utils/languages';
import getSortOrder from 'utils/sortOrder/getSortOrder';
import useZoneLanguages from './useZoneLanguages';

const useCategoryService = (): {
  getAllCategories: () => Promise<void>;
  createCategory: () => void;
  updateCategory: (category: Category) => Promise<void[]>;
} => {
  const { state: zoneData } = useZoneContext();
  const { state: vendorData } = useVendorContext();
  const { dispatch: toastDispatch } = useToastContext();
  const {
    dispatch: globalDispatch,
    state: { categories },
  } = useCategoryContext();
  const { dispatch: createDispatch, state: newCategoryState } = useNewCategoryContext();
  const { country, acceptedLanguages } = useZoneLanguages();
  const env = useEnvService().getEnv();
  const getAllCategories = useCallback(async () => {
    try {
      await getCategories(globalDispatch, zoneData);
    } catch (error) {
      globalDispatch({ type: 'status updated', payload: 'failed' });
    }
    // Needed to call getAll when vendorData changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalDispatch, zoneData, vendorData]);
  /**
   * Makes update call for each language with updated category
   * @param updatedCategory the passed updated category by vendorCategoryId
   */
  const updateCategory = useCallback(
    (updatedCategory: Category) => {
      return Promise.all(
        acceptedLanguages.map(async (lang) => {
          const { name, id, parentId } = updatedCategory[lang];
          return update({
            ...updatedCategory,
            name,
            id,
            parentId,
            language: languageCodeString(lang, country),
            languageCode: languageCodeString(lang, country),
          }).then((resp) => {
            const { data: category } = resp;
            globalDispatch({
              type: 'category received',
              payload: { category, languageCode: lang },
            });
          });
        })
      );
    },
    [acceptedLanguages, country, globalDispatch]
  );
  // TODO: createNewCategory is not being covered see below
  // istanbul ignore next
  const createCategory = useCallback(async () => {
    const { categoryLevel, position, imgFile } = newCategoryState;
    let imageUrl = '';
    let sortOrder = 0;
    if (position === 'bottom') {
      if (categoryLevel === 1) {
        sortOrder = getSortOrder(Object.values(categories), position);
      }
    }
    if (position === 'top' && categoryLevel === 1) {
      // Update root level categories with incremented sort order if new category is set to position top
      Object.values(categories).forEach((category) => {
        updateCategory({ ...category, sortOrder: Number(category.sortOrder) + 1 });
      });
    }
    if (imgFile) {
      imageUrl = await uploadFile(imgFile, env);
    }
    handleSubmit(createDispatch, {
      ...newCategoryState,
      sortOrder,
      imageUrl,
    }).catch(() => {
      showToast(toastDispatch, {
        severity: 'error',
        text: 'Failed to create your Category.',
      });
    });
  }, [newCategoryState, createDispatch, categories, updateCategory, env, toastDispatch]);

  return {
    getAllCategories,
    createCategory,
    updateCategory,
  };
};

export default useCategoryService;
