import { useCategoryContext } from 'context/CategoryContext';
import { useEffect, useState } from 'react';
import { Category } from 'types';

/**
 * Get category from global context based on passed vendorCategoryId
 * @param id vendorCategoryId
 * @returns Category
 */
const useCategoryById = (id: string): Category | null => {
  const {
    state: { categories },
  } = useCategoryContext();
  const [category, setCategory] = useState<Category | null>(null);
  useEffect(() => {
    setCategory(categories[id]);
    return () => {
      setCategory(null);
    };
  }, [id, categories]);
  return category;
};

export default useCategoryById;
