import { CategoryWithLanguageCode, useCategoryContext } from 'context/CategoryContext';
import _ from 'lodash';
import { useEffect, useState } from 'react';

/**
 * Get a sorted list of top level categories based on the selected language
 * @returns An array of sorted top level categories with language based values
 */
const useCategoryList = (): {
  categories: CategoryWithLanguageCode[];
} => {
  const {
    state: { categories: categoryState },
  } = useCategoryContext();
  const [categories, setCategories] = useState<CategoryWithLanguageCode[]>([]);

  useEffect(() => {
    if (_.isEmpty(categoryState)) return;
    // TODO: Figure out how to test lodash callbacks
    const sortedCategories = _.sortBy(
      Object.values(categoryState).filter((category) => category.categoryLevel === 0),
      /* istanbul ignore next */ (c) => c.sortOrder
    );
    setCategories(sortedCategories);
  }, [categoryState]);

  return {
    categories,
  };
};

export default useCategoryList;
