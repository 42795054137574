// istanbul ignore file
import { useCategoryContext } from 'context/CategoryContext';
import { EditMode, useListContext } from 'context/list-context';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Category } from 'types';
import { getUpdatedSortOrders } from 'utils/sortOrder';
import useSort from './useSort';

/**
 * Return all children with the passed id as parentVendorCategoryId
 * @param id vendorCategoryId
 * @returns Category array
 */
const useSubcategories = (
  id: string,
  setUpdatedSubcategories: ((array: Category[]) => void) | undefined
): {
  subcategories: Category[];
  handleMove: (dragIndex: number, hoverIndex: number) => void;
  handleDrop: () => void;
  // eslint-disable-next-line max-params
} => {
  const {
    state: { categories },
  } = useCategoryContext();
  const {
    state: { editMode },
  } = useListContext();
  const [defaultSubcategories, setDefaultSubcategories] = useState<Category[]>([]);
  const [subcategories, setSubcategories] = useState<Category[]>([]);
  const { handleSort } = useSort();

  const handleMove = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setSubcategories(handleSort(subcategories, dragIndex, hoverIndex));
    },

    [handleSort, subcategories]
  );

  // Update subcategory order onDrop
  const handleDrop = () => {
    const updatedSubcategories = getUpdatedSortOrders(defaultSubcategories, subcategories);
    if (!setUpdatedSubcategories) return;
    setUpdatedSubcategories(updatedSubcategories);
  };

  useEffect(() => {
    const initialSubcategories = Object.values(categories).filter(
      (c) => c.parentVendorCategoryId === id
    );
    const sorted = _.sortBy(initialSubcategories, (c) => c.sortOrder);
    setDefaultSubcategories(sorted);
    setSubcategories(sorted);
  }, [categories, id, setDefaultSubcategories]);

  // resets the subcategory tree if cancel button clicked
  useEffect(() => {
    if (editMode !== EditMode.sort) {
      setSubcategories(defaultSubcategories);
    }
  }, [editMode, defaultSubcategories]);

  return {
    subcategories,
    handleMove,
    handleDrop,
  };
};

export default useSubcategories;
