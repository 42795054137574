import { useUserMetadata } from 'admin-portal-shared-services';
import { Toast } from 'components';
import { ListProvider } from 'context/list-context';
import { NewCategoryProvider } from 'context/new-category';
import { setVendors, useVendorContext } from 'context/vendor-context';
import { useCategoryService, useItemService, useZoneLanguages } from 'hooks';
import _ from 'lodash';
import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';
import config from 'vendor-ids.config.json';

const CategoryHome = lazy(() => import('pages/CategoryHome/CategoryHome'));
const NewCategory = lazy(() => import('pages/NewCategory/NewCategory'));
const EditCategory = lazy(() => import('pages/EditCategory/EditCategory'));
const Products = lazy(() => import('pages/Products/Products'));

const Router = (): JSX.Element => {
  const { country } = useZoneLanguages();
  const { getAllCategories } = useCategoryService();
  const { getAllItems } = useItemService();
  const { data, isLoading } = useUserMetadata();
  const isGlobalVendorManager = data?.authorization?.scopes?.includes('CategoryAdmin.Vendor.All');
  const {
    dispatch,
    state: { vendors },
  } = useVendorContext();

  useEffect(() => {
    const setAllVendors = () => {
      const portalConfig = localStorage.getItem('portal-config');
      if (portalConfig) {
        let env = JSON.parse(portalConfig).ENV;
        env = env === 'qa' ? 'sit' : env;
        // Gets vendors from local config for each country
        const countriesWithVendors = Object.keys(config).reduce((acc, currentCountry) => {
          // @ts-expect-error currentCountry should never be falsey
          const countryVendors = config[currentCountry];
          return {
            ...acc,
            // Map over and get all vendors for country
            [currentCountry]: Object.keys(countryVendors).map((vendor) => {
              return {
                id: countryVendors[vendor][env],
                displayName: vendor.toUpperCase(),
              };
            }),
          };
        }, {});
        setVendors(dispatch, countriesWithVendors);
      }
    };
    /* istanbul ignore else */
    if (!isLoading) {
      // Checks if user has all vendor access
      if (isGlobalVendorManager && _.isEmpty(vendors)) {
        setAllVendors();
      }
      // Checks if the useUserMetadata hook contains vendors for the user
      else if (_.isEmpty(vendors) && data?.vendors?.length) {
        // Creates an object with country as key and array of vendors as values
        const mappedVendors = _.groupBy(data?.vendors, 'country');
        setVendors(dispatch, mappedVendors);
      } else {
        getAllCategories();
        getAllItems();
      }
    }
  }, [
    data,
    dispatch,
    country,
    getAllCategories,
    getAllItems,
    vendors,
    isLoading,
    isGlobalVendorManager,
  ]);
  return (
    <BrowserRouter>
      <Toast />
      <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
        <ListProvider>
          <NewCategoryProvider>
            <Switch>
              <Route path="/browse-category/new-category" component={NewCategory} />
              <Route
                path="/browse-category/edit-category/:vendorCategoryId"
                component={EditCategory}
              />
              <Route exact path="/browse-category/products" component={Products} />
              <Route path="/browse-category" component={CategoryHome} />
            </Switch>
          </NewCategoryProvider>
        </ListProvider>
      </Suspense>
    </BrowserRouter>
  );
};
export default Router;
