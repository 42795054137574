import { Divider, Typography } from '@material-ui/core';
import React, { CSSProperties } from 'react';

type HeaderProps = {
  controls?: JSX.Element;
  hasBottomDivider?: boolean;
  hasSubtitleDivider?: boolean;
  style?: CSSProperties;
  subtitle?: string | JSX.Element;
  title: string | JSX.Element;
  titleVariant?: 'h4' | 'h6';
};
const defaultProps = {
  controls: null,
  hasBottomDivider: false,
  hasSubtitleDivider: false,
  style: {},
  subtitle: '',
  titleVariant: 'h4',
};

const Header = ({
  controls,
  style,
  subtitle,
  title,
  titleVariant,
  hasBottomDivider,
  hasSubtitleDivider,
}: HeaderProps): JSX.Element => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          ...style,
        }}
      >
        <Typography
          variant={titleVariant}
          style={{ display: 'flex', alignItems: 'baseline', flexWrap: 'wrap', marginRight: 'auto' }}
        >
          {title}
          {!!subtitle && (
            <Typography style={{ paddingLeft: 4 }} variant="subtitle1">
              {hasSubtitleDivider && <span style={{ paddingRight: 4 }}>/</span>}
              {subtitle}
            </Typography>
          )}
        </Typography>
        {controls && <>{controls}</>}
      </div>
      {hasBottomDivider && <Divider style={{ margin: '32px 0', backgroundColor: '#CCCCCC' }} />}
    </>
  );
};

Header.defaultProps = defaultProps;

export default Header;
