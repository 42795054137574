// istanbul ignore file
// ! This file is being mocked globally due to the read-only nature of Item API in this context
import Api from 'Api/Api';
import { AxiosResponse } from 'axios';
import GET_API_ENDPOINTS from 'constants/services';

/**
 * Get all products from the Item API
 * @returns An array of items and pagination information
 */
const getItems = (page: number, pageSize: number): Promise<AxiosResponse> => {
  const API_ENDPOINTS = GET_API_ENDPOINTS();
  const vendorId = localStorage.getItem('vendor_Id');
  if (!vendorId) {
    throw new Error('No vendor configured.');
  }
  return Api().get({
    url: `${API_ENDPOINTS.items}?vendorId=${vendorId}&includeDeleted=false&includeDisabled=true&page=${page}&pageSize=${pageSize}`,
  });
};

// eslint-disable-next-line import/prefer-default-export
export { getItems };
