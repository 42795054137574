import { useTheme } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Props } from 'components/CategoryListItem/CategoryListItem';
import useCategoryListItemStyles from 'components/CategoryListItem/CategoryListItem.styles';
import { EditMode, toggleListItemOpen, useListContext } from 'context/list-context';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BLUE } from 'themes/customTheme';

const useCategoryListItem = (
  props: Props
): {
  isOpen: boolean;
  isSelected: boolean;
  handleClick: () => void;
  handleDetailsClick: (vendorCategoryId: string) => void;
  setDragIconColor: () => string;
  handleMouseDown: () => void;
  handleMouseLeave: () => void;
  listClasses: ClassNameMap;
} => {
  const { category, indentation, isParentSelected, parentId } = props;
  const {
    state: { openListIds, selectedListId, editMode },
    dispatch,
  } = useListContext();
  const { id, categories } = category;
  const listClasses = useCategoryListItemStyles({
    indentation,
    isRoot: category.categoryLevel === 0,
    isChildless: !categories.length,
    isParentSelected,
    editMode,
  });
  const [isGrabbed, setIsGrabbed] = useState(false);
  const isOpen = !!openListIds.includes(id);
  const isSelected = selectedListId === id;
  const history = useHistory();
  const handleClick = () => {
    toggleListItemOpen(dispatch, category);
  };
  const theme = useTheme();

  const handleDetailsClick = (vendorCategoryId: string) => {
    history.push(`/browse-category/edit-category/${vendorCategoryId}`);
  };

  const setDragIconColor = () => {
    const canDrag = editMode === EditMode.sort && !openListIds.includes(id);
    if (isGrabbed && canDrag) {
      return BLUE;
    }
    if (!canDrag && parentId) {
      return theme.palette.text.disabled;
    }
    return theme.palette.primary.main;
  };

  const handleMouseDown = () => {
    if (editMode) {
      setIsGrabbed(true);
    } else {
      setIsGrabbed(false);
    }
  };

  const handleMouseLeave = () => {
    setIsGrabbed(false);
  };

  return {
    isOpen,
    isSelected,
    handleClick,
    handleDetailsClick,
    handleMouseDown,
    handleMouseLeave,
    setDragIconColor,
    listClasses,
  };
};

export default useCategoryListItem;
