import { useZoneContext } from 'context/zone-context';
import { CountryCode } from 'types';

const useZoneLanguages = (): {
  country: CountryCode;
  defaultLanguage: string;
  acceptedLanguages: string[];
} => {
  const {
    state: { country, defaultLanguage, acceptedLanguages },
  } = useZoneContext();
  // Return default language first
  acceptedLanguages.sort((a: string, b: string) => {
    if (a === defaultLanguage && b !== defaultLanguage) {
      return -1;
    }
    return 0;
  });
  return {
    country,
    defaultLanguage,
    acceptedLanguages,
  };
};

export default useZoneLanguages;
