import { createTheme } from '@material-ui/core/styles';

export const IMAGE_COLOR = '#AAAAAA';

export const ENABLED_GREEN = '#19A453';
export const DISABLED_RED = '#C9201D';
export const WHITE = '#FFF';
export const BG_GRAY = '#F3F3F3';
export const DARK_GRAY = '#707372';
export const BORDER_GRAY = '#DFDEDE';
export const BG_LIGHT_GRAY = '#F2F2F2';
export const BLACK = '#000';
export const BLUE = '#4D6EDB';

export const PLACE_CENTER = { display: 'flex', justifyContent: 'center', alignItems: 'center' };

const customTheme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
        fontWeight: 500,
        padding: '.75rem',
      },
    },
    MuiButton: {
      contained: {
        boxShadow:
          '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
      },
    },
    MuiTableContainer: {
      root: {
        maxHeight: 440,
      },
    },
    MuiTableCell: {
      root: {
        fontWeight: 400,
      },
    },
    MuiOutlinedInput: {
      root: {
        marginBottom: 8,
      },
    },
  },
  typography: {
    fontFamily: 'Work Sans, sans-serif',
    fontWeightLight: 200,
    fontWeightMedium: 400,
    fontWeightBold: 600,
    button: {
      padding: '7 20',
      fontSize: 16,
      minWidth: 64,
      fontWeight: 600,
    },
    h2: {
      fontSize: 21,
    },
    h4: {
      fontWeight: 500,
      fontSize: 32,
      color: '#263238',
    },
    h6: {
      fontWeight: 600,
      fontSize: 21,
    },
    body2: {
      fontWeight: 500,
      color: BLACK,
    },
    subtitle1: {
      color: DARK_GRAY,
    },
    subtitle2: {
      color: BLACK,
    },
  },
  palette: {
    common: {
      white: WHITE,
      black: BLACK,
    },
    primary: {
      main: BLACK,
    },
    grey: {
      A100: BG_GRAY,
      A200: DARK_GRAY,
    },
  },
});

export default customTheme;
