import { AxiosResponse } from 'axios';
import config from 'countries.config.json';
import _ from 'lodash';
import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { create, update } from 'services/categories/CategoryService';
import { Category, ItemSelection, Props } from 'types';
import getUpdatedImageUrls from 'utils/getUpdatedImageUrls';
import getSortOrder from 'utils/sortOrder/getSortOrder';
import { v4 as uuidv4 } from 'uuid';

const initialState: NewCategoryState = {
  name: '',
  enabled: true,
  categoryLevel: 1,
  imgFile: null,
  position: 'bottom',
  parentCategories: [],
  items: [],
  categoryNames: {},
  status: {
    categoryNameError: false,
    parentCategoryError: false,
    premiumError: false,
    success: false,
    hasChanged: false,
  },
  categoryType: 'default',
  premium: '',
};

const NewCategoryContext = createContext<{
  state: NewCategoryState;
  dispatch: Dispatch<NewCategoryActions>;
}>({
  state: initialState,
  dispatch: () => undefined,
});
NewCategoryContext.displayName = 'NewCategoryContext';

type NewCategoryStateKeys = {
  [name: string]: any;
};

export type NewCategoryState = {
  name: string;
  enabled: boolean;
  imgFile: File | null;
  categoryLevel: number;
  position: string;
  parentCategories: ParentCategory[];
  items: ItemSelection[];
  categoryNames: CategoryName;
  status: {
    // Separate errors to allow for correct reference when focusing
    categoryNameError: boolean;
    parentCategoryError: boolean;
    premiumError: boolean;
    success: boolean;
    hasChanged: boolean;
  };
  categoryType: string;
  premium: string;
} & NewCategoryStateKeys;

export type ParentCategory = {
  name: string;
  id: string;
  vendorCategoryId: string;
  language: string;
  categories: Category[];
  languageCode: string;
};

type CategoryName = {
  [name: string]: string;
};

interface Action {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

type UpdateFieldAction = Action & {
  payload: { name: string; value: string | number | boolean | File | null };
};

type ErrorAction = Action & {
  payload: string[];
};

type UpdateParentCategoryAction = Action & {
  type: string;
  payload: { name: string; id: string };
};

type UpdateCategoryNamesAction = Action & {
  type: string;
  payload: { language: string; name: string };
};

type NewCategoryActions =
  | UpdateFieldAction
  | ErrorAction
  | Action
  | UpdateParentCategoryAction
  | UpdateCategoryNamesAction;

const stateHasChanged = (fieldName: string, fieldValue: any): boolean =>
  Array.isArray(fieldValue)
    ? fieldValue.length === initialState[fieldName].length
    : fieldValue === initialState[fieldName];

const newCategoryReducer = (
  state: NewCategoryState,
  action: NewCategoryActions
): NewCategoryState => {
  const { type, payload } = action;
  switch (type) {
    case 'field updated': {
      const fieldName = payload?.name;
      const fieldValue = payload?.value;
      const isPremium = fieldName === 'categoryType' && fieldValue === 'premium';
      const isClubB = fieldName === 'categoryType' && fieldValue.includes('club_b');
      return {
        ...state,
        // Set category level to 1 on premium type categories
        ...(isPremium && { categoryLevel: 1, premium: '' }),
        // Set premium value to club_b on select
        ...(isClubB && { premium: fieldValue }),
        [`${fieldName}`]: fieldValue,
        status: {
          ...state.status,
          hasChanged: !stateHasChanged(fieldName, fieldValue),
        },
      };
    }
    case 'fields reset':
      return initialState;
    case 'empty field error':
      return {
        ...state,
        status: {
          // TODO: Come up with a better way to handle field validation errors
          ...state.status,
          categoryNameError: payload?.error === 'categoryNames',
          parentCategoryError: payload?.error === 'parentCategories',
          premiumError: payload?.error === 'premium',
        },
      };
    case 'update parent category': {
      let newCategories = [...state.parentCategories];
      if (newCategories.find((item) => item.id === payload?.id)) {
        newCategories = newCategories.filter((item) => item.id !== payload?.id);
      } else {
        const { id, name, vendorCategoryId, language, categories, languageCode } = payload;
        newCategories = [
          ...newCategories,
          { name, id, vendorCategoryId, language, categories, languageCode },
        ];
      }
      return {
        ...state,
        status: {
          ...state.status,
          parentCategoryError: false,
          hasChanged: true,
        },
        parentCategories: newCategories,
      };
    }
    case 'update category names': {
      const { language, name } = payload;
      const { categoryNameError } = state.status;
      return {
        ...state,
        categoryNames: { ...state.categoryNames, [language]: name },
        status: {
          ...state.status,
          hasChanged: !!name.trim().length,
          // Clear error state if valid input is made
          categoryNameError: categoryNameError && !name.trim().length,
        },
      };
    }
    case 'success':
      return {
        ...state,
        status: {
          categoryNameError: false,
          parentCategoryError: false,
          premiumError: false,
          success: true,
          hasChanged: true,
        },
      };
    default:
      return state;
  }
};

const useNewCategoryContext = (): {
  state: NewCategoryState;
  dispatch: Dispatch<NewCategoryActions>;
} => {
  return useContext(NewCategoryContext);
};

const NewCategoryProvider = ({ children }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(newCategoryReducer, initialState);
  return (
    <NewCategoryContext.Provider value={{ state, dispatch }}>
      {children}
    </NewCategoryContext.Provider>
  );
};

const updateCategoryField = (
  dispatch: Dispatch<UpdateFieldAction>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updatedField: { name: string; value: any }
): void => {
  dispatch({ type: 'field updated', payload: { ...updatedField } });
};

const resetNewCategoryFields = (dispatch: Dispatch<Action>): void => {
  dispatch({ type: 'fields reset' });
};

// ! TODO: Handle premium value assignment based on type club b and default premium input
const handleSubmit = async (
  dispatch: Dispatch<NewCategoryActions>,
  category: NewCategoryState & { sortOrder: number | null; imageUrl: string }
): Promise<void> => {
  const country = localStorage.getItem('selectedCountry');
  // @ts-expect-error country should never be falsey
  const { acceptedLanguages } = config[localStorage.getItem('selectedCountry')];
  const {
    enabled,
    categoryNames,
    items,
    parentCategories,
    categoryLevel,
    categoryType,
    position,
    sortOrder,
    imageUrl,
    premium,
  } = category;
  const vendorCategoryId = uuidv4();
  const requests: Promise<AxiosResponse>[] = [];
  const image = { image: getUpdatedImageUrls(imageUrl) };
  const getRequestBody = (lang: string) => ({
    vendorCategoryId,
    ...(imageUrl && image),
    name: categoryNames[lang],
    type: 'CATEGORY',
    sortOrder,
    enabled,
    ...(items.length && {
      items: items.map((item, index) => ({ ...item, sortOrder: index })),
    }),
    ...(categoryType !== 'default' && {
      premium: premium.length ? [premium] : [],
    }),
  });
  if (categoryLevel > 1 && parentCategories.length) {
    const grouped = _.groupBy(parentCategories, 'vendorCategoryId');
    // Create new category with unique vendorCategoryId for each parent
    _.each(grouped, (val) => {
      const levelTwoVendorCategoryId = uuidv4();
      val.forEach((parent) => {
        if (position === 'top') {
          // Re-index siblings on position top
          const newSiblings = parent.categories.map((c) => ({
            ...c,
            sortOrder: Number(c.sortOrder) + 1,
          }));
          newSiblings.forEach((c) => {
            requests.push(update(c));
          });
        }
        const languageCode = `${parent.language}-${localStorage.getItem('selectedCountry')}`;
        requests.push(
          create(
            {
              ...getRequestBody(parent.language),
              vendorCategoryId: levelTwoVendorCategoryId,
              sortOrder: getSortOrder(parent.categories, position),
              parentId: parent.id,
            },
            languageCode
          )
        );
      });
    });
  } else {
    acceptedLanguages.forEach((lang: string) =>
      requests.push(create({ ...getRequestBody(lang), sortOrder }, `${lang}-${country}`))
    );
  }
  Promise.allSettled(requests).then(() => {
    // TODO: Add error handling, etc
    dispatch({ type: 'success' });
    dispatch({ type: 'fields reset' });
  });
  // TODO: add catch and dispatch error
};

const updateParentCategories = (
  dispatch: Dispatch<UpdateParentCategoryAction>,
  parentCategory: {
    name: string;
    id: string;
    language: string;
    vendorCategoryId: string;
    categories?: Category[];
    languageCode?: string;
  }
): void => {
  dispatch({ type: 'update parent category', payload: { ...parentCategory } });
};

const updateCategoryNames = (
  dispatch: Dispatch<UpdateCategoryNamesAction>,
  categoryName: { language: string; name: string }
): void => {
  dispatch({ type: 'update category names', payload: { ...categoryName } });
};

export {
  NewCategoryContext,
  NewCategoryProvider,
  useNewCategoryContext,
  updateCategoryField,
  resetNewCategoryFields,
  newCategoryReducer,
  handleSubmit,
  updateParentCategories,
  updateCategoryNames,
};
