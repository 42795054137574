import React, { createContext, Dispatch, useContext, useReducer } from 'react';

type Vendor = {
  id: string | undefined;
  country: string | undefined;
  displayName: string | undefined;
  serviceModel: string | undefined;
  governmentId: string | undefined;
  abiVendorId: string | undefined;
};
type Vendors = {
  [name: string]: Vendor[];
};

type VendorData = {
  vendors: Vendors;
  selectedVendor?: Vendor | '';
};

interface VendorActions {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

const VendorContext = createContext<{ state: VendorData; dispatch: Dispatch<VendorActions> }>({
  state: {} as VendorData,
  dispatch: () => undefined,
});

VendorContext.displayName = 'VendorContext';

const vendorReducer = (state: VendorData, action: VendorActions): VendorData => {
  const { type, payload } = action;
  const selectedCountry = localStorage.getItem('selectedCountry') as string;
  switch (type) {
    case 'set vendors': {
      const countryDefaultVendor = payload[selectedCountry]
        ? payload[selectedCountry][0]
        : undefined;
      if (countryDefaultVendor) {
        localStorage.setItem('vendor_Id', countryDefaultVendor.id);
      }
      return {
        vendors: payload,
        selectedVendor: countryDefaultVendor,
      };
    }
    case 'update vendors': {
      const countryVendors = state.vendors[payload];
      const defaultVendor = countryVendors ? countryVendors[0] : undefined;
      if (defaultVendor) {
        localStorage.setItem('vendor_Id', defaultVendor.id as string);
      } else {
        // Remove so other countries do not use previous vendorId
        localStorage.removeItem('vendor_Id');
      }
      return {
        ...state,
        selectedVendor: defaultVendor,
      };
    }
    case 'update selected vendor': {
      const { vendors } = state;
      const selectedVendor = vendors[selectedCountry].find(
        (vendor: Vendor) => vendor.displayName === payload
      );
      localStorage.setItem('vendor_Id', selectedVendor?.id as string);
      return {
        ...state,
        selectedVendor,
      };
    }
    default:
      return state;
  }
};

const VendorProvider = (props: { children: JSX.Element }): JSX.Element => {
  const { children } = props;

  const initialState = {
    vendors: {},
    selectedVendor: undefined,
  };

  const [state, dispatch] = useReducer(vendorReducer, initialState);
  return <VendorContext.Provider value={{ state, dispatch }}>{children}</VendorContext.Provider>;
};

const useVendorContext = (): { state: VendorData; dispatch: Dispatch<VendorActions> } => {
  return useContext(VendorContext);
};
/* istanbul ignore next */
const setVendors = (dispatch: Dispatch<VendorActions>, vendors: Vendors): void => {
  dispatch({ type: 'set vendors', payload: vendors });
};

const updateVendors = (dispatch: Dispatch<VendorActions>, country: string): void => {
  dispatch({ type: 'update vendors', payload: country });
};
/* istanbul ignore next */
const setSelectedVendor = (dispatch: Dispatch<VendorActions>, vendor: string): void => {
  dispatch({ type: 'update selected vendor', payload: vendor });
};

export {
  VendorProvider,
  useVendorContext,
  vendorReducer,
  VendorContext,
  setVendors,
  updateVendors,
  setSelectedVendor,
};
