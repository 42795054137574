import { makeStyles } from '@material-ui/core';
import React, { FC, useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { BG_GRAY } from 'themes/customTheme';

const sortHover = makeStyles({
  hovering: {
    '&:hover': {
      backgroundColor: BG_GRAY,
    },
  },
});

const style = {
  cursor: 'pointer',
};

export type ItemProps = {
  index: number;
  onMove: (dragIndex: number, hoverIndex: number) => void;
  onDrop: () => void;
  children: React.ReactNode;
  canDrag?: boolean;
  canDrop?: boolean;
  parentId?: string;
  customDragArea?: boolean;
  type: string;
};

type DragItem = {
  index: number;
  parentId: string;
  type: string;
};

const defaultProps = {
  canDrag: true,
  canDrop: true,
  customDragArea: false,
};

const SortableItem: FC<ItemProps> = ({
  index,
  onMove,
  children,
  canDrag,
  parentId,
  type,
  onDrop,
  customDragArea,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const classes = sortHover();

  const [{ handlerId, canDrop }, drop] = useDrop({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        canDrop: monitor.canDrop(),
      };
    },
    /* istanbul ignore next work through when implemented in parent */
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      // Checks to see if dragItem parentId is different than hover item parentId
      if (item.parentId !== parentId) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine item on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get items vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 3;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      if (monitor.canDrop()) {
        onMove(dragIndex, hoverIndex);
      }

      item.index = hoverIndex;
    },
  });

  /* 
    When customDragArea is false you can drag from the entire row. No need for a preview. 
    When customDragArea is true, we're saying preview this row but only allow dragging from the dragRef. 
    Preview is only needed when you want to drag from a specific area and preview the row you're dragging 
  */
  const [{ isDragging }, drag, preview] = useDrag({
    type,
    canDrag: () => !!canDrag,
    item: () => {
      return { index, parentId };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (_currentItem, monitor) => {
      // Null value is to check if it was moved outside of dropzone but sibling items moved
      /* istanbul ignore else */
      if (monitor.didDrop() || monitor.getDropResult() === null) {
        onDrop();
      }
    },
  });

  /* istanbul ignore next */
  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <div
      data-handler-id={handlerId}
      className={!isDragging && !canDrop ? classes.hovering : ''}
      data-testid={`${index}-sortable`}
      style={{ ...style, opacity }}
      ref={customDragArea ? preview : ref} // see useDrag
    >
      {React.cloneElement(children, { dragRef: ref })}
    </div>
  );
};

SortableItem.defaultProps = defaultProps;

export default SortableItem;
