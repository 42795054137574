/* eslint-disable @typescript-eslint/no-explicit-any */
import Api from 'Api/Api';
import { AxiosResponse } from 'axios';
import GET_API_ENDPOINTS from 'constants/services';
import _ from 'lodash';
import { Category } from 'types';

const getAll = (categoryLanguage: string): Promise<Category[]> => {
  const API_ENDPOINTS = GET_API_ENDPOINTS();
  const vendorId = localStorage.getItem('vendor_Id');
  if (!vendorId) {
    throw new Error('No vendor configured.');
  }
  const url = `${API_ENDPOINTS.categoriesV2}?vendorId=${vendorId}&includeDisabled=true&premium=all`;
  /**
   * Check for premium field values for the selected country and if
   * any exist, add the premium query param to the GET request URL
   */
  return Api(categoryLanguage)
    .get({
      url,
    })
    .then((res) => {
      const sortedData = _.sortBy(res.data, ['sortOrder']);
      sortedData.forEach((item: Category) => {
        if (item.categories.length > 0) {
          item.categories = _.sortBy(item.categories, ['sortOrder']);
        }
      });
      return sortedData;
    });
};

const getById = (id: string, languageCode: string): Promise<Category> => {
  const API_ENDPOINTS = GET_API_ENDPOINTS();
  const vendorId = localStorage.getItem('vendor_Id');
  return Api(languageCode).get({
    url: `${API_ENDPOINTS.categoriesV2}${id}?vendorId=${vendorId}`,
  });
};

const create = async (
  body: {
    name: string;
    vendorCategoryId: string;
    type: string;
    enabled: boolean;
    parentId?: string;
    sortOrder?: number | null;
  },
  languageCode: string
): Promise<AxiosResponse> => {
  const API_ENDPOINTS = GET_API_ENDPOINTS();
  return Api(languageCode).post({
    url: API_ENDPOINTS.categories,
    body,
  });
};

const update = (body: Category): Promise<AxiosResponse> => {
  const { languageCode } = body;
  const { id } = body;
  const API_ENDPOINTS = GET_API_ENDPOINTS();
  return Api(languageCode).put({
    url: `${API_ENDPOINTS.categories}/${id}`,
    body,
  });
};

export { getAll, getById, create, update };
