/* eslint-disable @typescript-eslint/no-explicit-any */
import useZoneLanguages from 'hooks/useZoneLanguages';
import React from 'react';
import { DARK_GRAY } from 'themes/customTheme';

const getNameValue = (data: any) => (data && data.name ? data.name : 'Not Set');

const CategoryName = ({ category }: { category: any }): JSX.Element => {
  const { defaultLanguage, acceptedLanguages } = useZoneLanguages();
  const secondaryLanguages = acceptedLanguages
    .filter((lang) => lang !== defaultLanguage)
    .map((lang) => {
      return getNameValue(category[lang]);
    });
  return (
    <>
      <span style={{ whiteSpace: 'nowrap' }} data-testid="default-category-name">
        {getNameValue(category[defaultLanguage])}
      </span>
      {!!secondaryLanguages.length && (
        <span
          data-testid="secondary-category-name"
          style={{ color: DARK_GRAY, paddingLeft: 4, whiteSpace: 'nowrap' }}
        >
          {`(${secondaryLanguages.join(', ')})`}
        </span>
      )}
    </>
  );
};

export default CategoryName;
