import { makeStyles } from '@material-ui/core';
import { BORDER_GRAY, WHITE } from 'themes/customTheme';

const useTableStyles = makeStyles({
  root: {
    // Overrides right alignment of last cell from default theme
    '& .left-aligned': {
      textAlign: 'left',
    },
    '& .center-aligned': {
      textAlign: 'center',
    },
  },
});

const useHeaderStyles = makeStyles({
  root: {
    '& th': {
      // Override stickyHeader styling
      borderTop: `1px solid ${BORDER_GRAY}`,
      borderBottom: `1px solid ${BORDER_GRAY}`,
      backgroundColor: WHITE,
      fontWeight: 500,
      paddingTop: 16.5,
      paddingBottom: 16.5,
      fontSize: 14,
      // Remove styling for checkboxes in header
      '& span': {
        color: '#000',
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
});

const useRowStyles = makeStyles({
  root: {
    borderBottom: `1.02px solid ${BORDER_GRAY}`,
    '& td > button:focus:hover': {
      color: '#4D6EDB ',
    },
    '& td': {
      // Override maxWidth: 0 from default theme
      maxWidth: 'none',
      '& button:hover': {
        background: 'transparent',
      },
    },
  },
});

const usePaginationStyles = makeStyles({
  root: {
    margin: '20px 0',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${BORDER_GRAY}`,
    borderBottom: `1px solid ${BORDER_GRAY}`,
  },
});

export { useHeaderStyles, useTableStyles, useRowStyles, usePaginationStyles };
