import { Snackbar } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { hideToast, useToastContext } from 'context/toast-context';
import React, { useCallback } from 'react';

const Toast = (): JSX.Element => {
  const { state, dispatch } = useToastContext();
  const { show, severity, text } = state;
  const handleClose = useCallback(() => {
    hideToast(dispatch);
  }, [dispatch]);

  return (
    <Snackbar
      style={{ width: 350, top: 88 }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={show}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        data-testid="toast"
        style={{ alignItems: 'center' }}
        iconMapping={{ success: <CheckCircle fontSize="small" /> }}
        onClose={handleClose}
        severity={severity}
        variant="filled"
      >
        {text}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
