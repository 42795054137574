// istanbul ignore file
// ! This file is being mocked globally due to the read-only nature of Item API in this context
import _ from 'lodash';
import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { Item, Props } from 'types';

const initialState: ItemState = {
  items: {},
  status: 'idle',
};

type ItemState = {
  items: { [id: string]: Item };
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
};

interface Action {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

type ItemActions = Action;

const ItemContext = createContext<{ state: ItemState; dispatch: Dispatch<ItemActions> }>({
  state: initialState,
  dispatch: () => undefined,
});
ItemContext.displayName = 'ItemContext';

const itemReducer = (state: ItemState, action: ItemActions): ItemState => {
  const { type, payload } = action;
  switch (type) {
    case 'items received': {
      return {
        ...state,
        items: _.keyBy(payload, (i) => i.sourceData.vendorItemId),
        status: 'idle',
      };
    }
    case 'status updated':
      return {
        ...state,
        status: payload,
      };
    default:
      return state;
  }
};

const useItemContext = (): {
  state: ItemState;
  dispatch: Dispatch<ItemActions>;
} => {
  return useContext(ItemContext);
};

const ItemProvider = ({ children }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(itemReducer, initialState);
  return <ItemContext.Provider value={{ state, dispatch }}>{children}</ItemContext.Provider>;
};

export { ItemProvider, useItemContext, itemReducer, ItemContext };
