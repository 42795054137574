import _ from 'lodash';
import { ItemSelection } from 'types';

const useProductTable = ({
  selected,
  handleSelect,
  items,
}: {
  selected: ItemSelection[];
  handleSelect: (value: ItemSelection | ItemSelection[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[];
}): {
  handleSelectAll: () => void;
  isSelectAllToggled: boolean;
  isSelectedOnPage: boolean;
} => {
  const itemValues = _.map(items, ({ sourceData }) => ({
    vendorItemId: sourceData?.vendorItemId,
  }));
  const isSelectAllToggled =
    !!selected.length &&
    _.isEqual(
      _.intersection(_.map(itemValues, 'vendorItemId'), _.map(selected, 'vendorItemId')),
      _.map(itemValues, 'vendorItemId')
    );
  const isSelectedOnPage = !!_.intersection(
    _.map(itemValues, 'vendorItemId'),
    _.map(selected, 'vendorItemId')
  ).length;
  const handleSelectAll = () => {
    if (!isSelectAllToggled && !isSelectedOnPage) {
      handleSelect(_.uniqBy([...selected, ...itemValues], 'vendorItemId'));
    } else {
      handleSelect(_.differenceBy(selected, itemValues, 'vendorItemId'));
    }
  };
  return {
    handleSelectAll,
    isSelectAllToggled,
    isSelectedOnPage,
  };
};

export default useProductTable;
