import { useAuthenticationService, useFeatureToggleService } from 'admin-portal-shared-services';
import { isLocatedInEurope } from 'utils/countries';
import toggles from '../toggles.config.json';

type ENDPOINTS = {
  categories: string;
  categoriesV2: string;
  uploadImage: string;
  items: string;
};

const GET_API_ENDPOINTS = (): ENDPOINTS => {
  const authentication = useAuthenticationService();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();

  const cmsEuClusterFT = useFeatureToggleService().isFeatureEnabled(toggles.v2.CMS_EU_CLUSTER);

  const selectedCountry = localStorage.getItem('selectedCountry');
  const country = selectedCountry || userCountry;
  const cluster = cmsEuClusterFT && isLocatedInEurope(country as string) ? '/eu' : '';

  return {
    categories: `/api${cluster}/categories`,
    categoriesV2: `/api${cluster}/categories/v2/`,
    uploadImage: `/api${cluster}/cms-content-edit-images/api/v1`,
    items: `/api${cluster}/items/items`,
  };
};

export default GET_API_ENDPOINTS;
