import { ArrowForwardIos } from '@material-ui/icons';
import { useCategoryContext } from 'context/CategoryContext';
import useZoneLanguages from 'hooks/useZoneLanguages';
import React from 'react';

const CategoryPath = ({ category }: { category: any }): JSX.Element => {
  const { defaultLanguage } = useZoneLanguages();
  const {
    state: { categories },
  } = useCategoryContext();
  const defaultLanguageData = category[defaultLanguage];
  const parentCategory = Object.values(categories).find((c) =>
    defaultLanguageData && c[defaultLanguage]
      ? c[defaultLanguage].id === defaultLanguageData.parentId
      : ''
  );

  return (
    <span data-testid="category-path" style={{ display: 'flex', alignItems: 'center' }}>
      {parentCategory && (
        <>
          {parentCategory.name}
          <ArrowForwardIos fontSize="small" style={{ margin: '0 6px', fontSize: 14 }} />
        </>
      )}
      <span>{defaultLanguageData ? defaultLanguageData.name : 'Not Set'}</span>
    </span>
  );
};

export default CategoryPath;
