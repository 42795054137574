import { Badge as MuiBadge, makeStyles } from '@material-ui/core';
import { CategoryIcon, ProductIcon } from 'assets/icons';
import React, { useCallback } from 'react';
import { BG_LIGHT_GRAY } from 'themes/customTheme';
import Tooltip from './Tooltip';

type Props = {
  error?: boolean;
  type: 'category' | 'product' | 'premium';
  value?: number | string;
};

const defaultProps = {
  error: false,
  value: '...',
};

const useCategoryBadgeStyles = makeStyles({
  root: (error) => {
    return {
      // !important overrides contrast styling on list item hover
      '& > span:hover': {
        backgroundColor: `${error ? '#F6C1C1' : '#E9F1FF'} !important`,
      },
    };
  },
  // Enforce background styling on item count badge
  badge: (error) => {
    return {
      backgroundColor: error ? '#FFDEDE' : BG_LIGHT_GRAY,
      // Override for default positioning on Badge
      transform: 'none',
      position: 'relative',
      marginRight: 8,
      '& svg': {
        width: 14,
        marginRight: 4,
      },
    };
  },
});

const Badge = ({ error, type, value }: Props): JSX.Element => {
  const classes = useCategoryBadgeStyles(error);
  const getTooltipMessage = useCallback(() => {
    if (type === 'category') {
      if (Number(value) < 1) {
        return "This category doesn't have any subcategories";
      }
      return 'Subcategories inside this category';
    }
    if (type === 'product' && error) {
      return "This category has subcategories so it won't show its product's assignments";
    }
    if (type === 'product' && Number(value) < 1) {
      return "This category doesn't have any products assigned to it";
    }
    return 'Products in this category';
  }, [error, type, value]);

  return (
    <Tooltip message={getTooltipMessage()}>
      <MuiBadge
        data-testid="info-badge"
        className={`badge ${error ? 'badge-error' : 'badge-normal'}`}
        classes={classes}
        badgeContent={
          <>
            {type === 'category' && <CategoryIcon />}
            {type === 'product' && <ProductIcon />}
            <span>{value}</span>
          </>
        }
        max={9999}
      />
    </Tooltip>
  );
};

Badge.defaultProps = defaultProps;

export default Badge;
