import _ from 'lodash';
import { useCallback, useState } from 'react';

const useHandleSearch = (
  resetPagination: () => void
): {
  handleClearSearch: () => void;
  handleSearch: (searchValues: { id: string; value: string }[]) => void;
  searchItems: string[];
} => {
  const [searchItems, setSearchItems] = useState<string[]>([]);
  const handleSearch = useCallback(
    (searchValues: { id: string; value: string }[]) => {
      setSearchItems(_.map(searchValues, ({ value }) => value.toUpperCase()));
      resetPagination();
    },
    [resetPagination]
  );
  const handleClearSearch = useCallback(() => {
    setSearchItems([]);
    resetPagination();
  }, [resetPagination]);

  return { handleClearSearch, handleSearch, searchItems };
};

export default useHandleSearch;
