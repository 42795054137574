import {
  Button,
  Checkbox,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import {
  DragIndicator,
  ExpandLess,
  ExpandMore,
  FormatListBulleted,
  StarBorder,
} from '@material-ui/icons';
import { KeyIcon } from 'assets/icons';
import { ConditionalWrapper } from 'components';
import { Badge, CategoryBadge, StatusIndicator } from 'components/Display';
import { CategoryName } from 'components/MultiLanguage';
import SortableItem from 'components/SortableItem/SortableItem';
import { EditMode, useListContext } from 'context/list-context';
import { useCategoryListItem, useSubcategories } from 'hooks';
import _ from 'lodash';
import React from 'react';
import { Category } from 'types';

export type Props = {
  category: Category;
  handleSelect?: (vendorCategoryId: string) => void;
  setUpdatedSubcategories?: (array: Category[]) => void;
  isEditing?: boolean;
  indentation?: number;
  isParentSelected?: boolean;
  parentId?: string;
  selected?: string[];
  dragRef?: undefined;
};

const defaultProps = {
  indentation: 0,
  isParentSelected: false,
  isEditing: false,
};

// TODO: Break up the list item into smaller components to reduce the cognitive complexity
const CategoryListItem = (props: Props): JSX.Element => {
  const {
    category,
    handleSelect,
    indentation,
    parentId,
    setUpdatedSubcategories,
    selected,
    dragRef,
  } = props;
  const { id, vendorCategoryId, enabled, categoryLevel, items, premium } = category;
  const {
    state: { editMode },
  } = useListContext();
  const { subcategories, handleMove, handleDrop } = useSubcategories(
    vendorCategoryId,
    setUpdatedSubcategories
  );
  // category.items is null if none are assigned
  const itemCount = items?.length || 0;
  const isRootCategory = categoryLevel === 0;
  const isEmpty = !itemCount && !subcategories.length;
  const isPremium =
    Array.isArray(premium) && !_.intersection(premium, ['club_b', 'club_b_max']).length;
  const {
    isOpen,
    isSelected,
    handleClick,
    handleDetailsClick,
    handleMouseDown,
    handleMouseLeave,
    listClasses,
    setDragIconColor,
  } = useCategoryListItem(props);

  const isSortingMode = editMode === EditMode.sort;
  const isSelectionMode =
    editMode === EditMode.select && selected && !subcategories?.length && !!handleSelect;
  const shouldShowEditModeIcon = isSortingMode || isSelectionMode;

  return (
    <div style={{ width: '100%' }}>
      <ListItem
        id={`category-list-item-${id}`}
        data-testid="category-list-item"
        classes={listClasses}
        // @ts-expect-error TypeScript expects the shorthand
        button={!editMode}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        selected={isSelected}
      >
        {shouldShowEditModeIcon && (
          <ListItemIcon
            ref={dragRef}
            data-testid="row-drag-handle"
            style={{
              minWidth: 36,
              padding: '0 0 0 1px',
            }}
          >
            {isSortingMode && <DragIndicator htmlColor={setDragIconColor()} />}
            {isSelectionMode && (
              <Checkbox
                checked={selected?.includes(vendorCategoryId)}
                color="primary"
                size="small"
                data-testid="category-item-checkbox"
                onChange={() => handleSelect && handleSelect(vendorCategoryId)}
                // Enforce unselected checkbox styling
                {...(!selected?.includes(vendorCategoryId) && {
                  style: { color: '#B9BCC1' },
                })}
              />
            )}
          </ListItemIcon>
        )}
        {/* Only hide expand arrow in select mode on categories with no children */}
        {!(editMode === EditMode.select && !subcategories.length) && (
          <IconButton style={{ padding: 8 }} disabled={!subcategories.length}>
            {isOpen ? (
              <ExpandLess fontSize="small" data-testid="expand-less-icon" />
            ) : (
              <ExpandMore
                fontSize="small"
                style={{ color: !subcategories.length ? '#DFDEDE' : '' }}
                data-testid="expand-more-icon"
              />
            )}
          </IconButton>
        )}
        <ListItemText
          primary={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className="category-item-text">
                <CategoryName category={category} />
              </span>
              {isPremium && (
                <Badge
                  value={<StarBorder />}
                  colors={{ defaultColor: '#D1F7F6' }}
                  tooltipContent="This is a Premium Category, it will only appear for some customers."
                />
              )}
              {Array.isArray(premium) &&
                !!_.intersection(premium, ['club_b', 'club_b_max']).length && (
                  <Badge
                    colors={{ defaultColor: '#FFEBD0', hover: '#FFD7A0' }}
                    value="B"
                    tooltipContent="This category will only be displayed to customers enrolled in the Club B Max program."
                  />
                )}
              {(!!subcategories.length || (isRootCategory && isEmpty)) && (
                <CategoryBadge value={subcategories.length} type="category" />
              )}
              {(!isRootCategory || !!itemCount || (isRootCategory && isEmpty)) && (
                <CategoryBadge
                  value={itemCount}
                  error={!!subcategories.length && !!itemCount}
                  type="product"
                />
              )}
              {isPremium && (
                <>
                  <KeyIcon />
                  <span style={{ color: '#939191', fontSize: 16, marginLeft: 4 }}>{premium}</span>
                </>
              )}
            </div>
          }
        />
        <StatusIndicator enabled={!!enabled} />
        {!editMode && (
          <ListItemSecondaryAction>
            <Button
              variant="contained"
              disableElevation
              data-testid="details-button"
              className="details-button"
              startIcon={<FormatListBulleted />}
              onClick={() => handleDetailsClick(vendorCategoryId)}
            >
              DETAILS
            </Button>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      <Collapse in={isOpen} unmountOnExit>
        <List disablePadding>
          {subcategories?.map((subCategory: Category, i) => {
            const { id: subId } = subCategory;
            return (
              <ConditionalWrapper
                key={subId}
                condition={editMode === EditMode.sort}
                wrapper={(children) => (
                  <SortableItem
                    parentId={parentId}
                    canDrag={isOpen && editMode === EditMode.sort}
                    index={i}
                    onMove={handleMove}
                    type="CHILD"
                    onDrop={handleDrop}
                  >
                    {children}
                  </SortableItem>
                )}
              >
                <CategoryListItem
                  key={subId}
                  category={subCategory}
                  indentation={Number(indentation)}
                  isParentSelected={isSelected}
                  handleSelect={handleSelect}
                  selected={selected}
                />
              </ConditionalWrapper>
            );
          })}
        </List>
      </Collapse>
    </div>
  );
};

CategoryListItem.defaultProps = defaultProps;

export default CategoryListItem;
