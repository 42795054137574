import { CircularProgress, List } from '@material-ui/core';
import { ConditionalWrapper } from 'components';
import SortableItem from 'components/SortableItem/SortableItem';
import { useCategoryContext } from 'context/CategoryContext';
import { EditMode, useListContext } from 'context/list-context';
import React from 'react';
import { Category } from 'types';
import CategoryListItem from '../CategoryListItem/CategoryListItem';

const defaultProps = {
  handleDrop: undefined,
  handleMove: undefined,
  handleUpdateSubcategories: undefined,
  handleSelect: undefined,
  selected: undefined,
};

type Props = {
  categories: Category[];
  handleDrop?: () => void;
  handleMove?: (dragIndex: number, hoverIndex: number) => void;
  handleSelect?: (vendorCategoryId: string) => void;
  handleUpdateSubcategories?: (array: Category[]) => void;
  selected?: string[];
};

const CategoryList = ({
  categories,
  handleDrop,
  handleMove,
  handleSelect,
  handleUpdateSubcategories,
  selected,
}: Props): JSX.Element => {
  const {
    state: { openListIds, selectedListId, editMode },
  } = useListContext();
  const {
    state: { status },
  } = useCategoryContext();
  return (
    <List data-testid="category-list" id="category-list" disablePadding>
      {status === 'loading' ? (
        <div
          data-testid="list-loading-indicator"
          style={{ display: 'flex', padding: 32, justifyContent: 'center' }}
        >
          <CircularProgress />
        </div>
      ) : (
        categories.map((category, i) => {
          const { id } = category;
          let canDrag = true;
          // Checks parent to see if opened and prevents dragging
          if (openListIds.includes(id) && category.categories.length) {
            canDrag = false;
          }
          return (
            <ConditionalWrapper
              key={id}
              condition={!!handleMove && !!handleDrop && editMode === EditMode.sort}
              wrapper={(children) => (
                <SortableItem
                  canDrag={canDrag && editMode === EditMode.sort}
                  canDrop={!selectedListId.includes(id)}
                  key={id}
                  index={i}
                  type="PARENT"
                  // @ts-expect-error SortableItem not rendered if no move/drop handler
                  onMove={handleMove}
                  // @ts-expect-error SortableItem not rendered if no move/drop handler
                  onDrop={handleDrop}
                  customDragArea
                >
                  {children}
                </SortableItem>
              )}
            >
              <CategoryListItem
                key={id}
                category={category}
                parentId={id}
                setUpdatedSubcategories={handleUpdateSubcategories}
                handleSelect={handleSelect}
                selected={selected}
              />
            </ConditionalWrapper>
          );
        })
      )}
    </List>
  );
};

CategoryList.defaultProps = defaultProps;

export default CategoryList;
